//TODO calculate the app bar properly

@font-face {
  font-family: "gibson";
  font-weight: 300;
  src: url("fonts/GibsonLight.OTF") format("opentype");
}

@font-face {
  font-family: "gibson";
  font-weight: 400;
  src: url("fonts/GibsonRegular.OTF") format("opentype");
}

@font-face {
  font-family: "gibson";
  font-weight: 600;
  src: url("fonts/GibsonSemiBd.OTF") format("opentype");
}

@font-face {
  font-family: "gibson";
  font-weight: 700;
  src: url("fonts/GibsonBold.OTF") format("opentype");
}

@mixin fontSize($fontSize) {
  html,
  body div#root {
    font-size: $fontSize;
  }
}

@media screen and (max-width: 600px) {
  @include fontSize(16px);
}
@media screen and (min-width: 601px) {
  @include fontSize(18px);
}

.centered-div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.height-100 {
  height: 100%;
}

// datepicker styles
.rdrDateDisplayWrapper {
  background-color: #f0eeeb;
}

.rdrStaticRange:hover .rdrStaticRangeLabel,
.rdrStaticRange:focus .rdrStaticRangeLabel {
  background-color: #f0eeeb;
}

.rdrMonthAndYearPickers select:hover {
  background-color: #f0eeeb;
}
.rdrNextPrevButton {
  background-color: #f0eeeb;
}

.rdrNextPrevButton:hover {
  background: #e1dcd3;
}

.rdrDayToday .rdrDayNumber span:after {
  background: #f16720;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

.float-right {
  float: right;
}

.bold-text {
  font-weight: 600;
}

.show-on-hover-parent:hover .show-on-hover,
.show-on-hover-parent:active .show-on-hover,
.rdt_Table .rdt_TableRow:hover .show-on-hover,
.rdt_Table .rdt_TableRow:active .show-on-hover {
  visibility: visible !important;
}

html {
  scroll-padding-top: 109px;
}

// MUI TABS GLOBAL CONFI
.MuiTabs-indicator {
  background-color: #ee741f;
}
