.MuiTreeItem-root.Mui-selected:focus > .MuiTreeItem-content .MuiTreeItem-label,
.MuiTreeItem-root.Mui-selected:active > .MuiTreeItem-content .MuiTreeItem-label,
.MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label,
.MuiTreeItem-content.Mui-selected,
.MuiTreeItem-content.Mui-focused,
.MuiTreeItem-label:visited,
.MuiTreeItem-label:active {
  background-color: #fdf1e9 !important;
}

.MuiTreeItem-content:hover,
.MuiTreeItem-label:hover {
  background-color: #f7f7f7 !important;
}
